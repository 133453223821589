<template>
 <div class="wrapper infoPanel">
    <div class="icon">
            <img src="@/assets/images/warning.svg" class="" loading="lazy" />
        </div>
        <div class="desc">
            <h2>Coś poszło nie tak. Spróbuj ponownie później.</h2>
            <div class="btn">
                <router-link :to="'/'">
                    <button class="back"><span>Wróć na stronę główną</span></button>
                </router-link>
            </div>
        </div>
    </div>
</template>
<script>
export default{
name: 'CheckoutFailure'
}
</script>
<style scoped>
</style>