<template>
    <section id="cartSidebar" :class="{ 'preload': !isDataFetched }">
        <div class="header">
            <h3><span>Koszyk</span> <span class="amount" v-if="countItemsInCart > 0">{{ countItemsInCart }}</span></h3>
            <img @click.prevent="closeSidebar()" src="@/assets/images/close.svg" class="close" loading="lazy" />
        </div>

        <div class="scroll-overflow" v-if="!getCartItems[0]">
            <div class="cart-empty-info">
                <div class="icon">
                    <img src="@/assets/images/empty-cart.svg" class="" loading="lazy" />
                </div>
                <div class="desc">
                    <p>Twój koszyk jest pusty.</p>
                    <ul>
                        <li><router-link :to="'/'"><button>Strona główna</button></router-link></li>
                        <li><router-link :to="'/favourites'"><button>Ulubione</button></router-link></li>
                    </ul>
                </div>
            </div>
        </div>

        <CartProductsList v-if="isDataFetched && getCartItems[0]"></CartProductsList>
    </section>
</template>
<script>
import axios from 'axios';

import CartProductsList from '../parts/CartProductsList';
import CartItem from '../parts/CartItem';

export default {
    name: 'CartSidebar',
    data() {
        return {
            isDataFetched: false,
            productsInCart: []
        }
    },
    components: {
        CartItem,
        CartProductsList
    },
    computed: {
        sidebarState() {
            return this.$store.state.layoutControllers.cartSidebar;
        },
        getCartItems() {
            return this.$store.getters.getCartItems;
        },
        countItemsInCart() {
            return this.$store.getters.countItemsInCart;
        },
        itemsIds() {
            let arr = [];
            this.getCartItems.forEach(elm => {
                arr.push(elm.id);
            });
            return arr.join([',']);
        }
    },
    methods: {
        getCartItemsData(ids) {
            const apiURL = "wp/v2/products?include=" + ids;
            if (this.getCartItems[0]) {
                axios.get(apiURL).then(({ data }) => {
                    this.productsInCart = data;
                    this.isDataFetched = true;
                })
                .catch(err => {
                    if(err.status === 403){
                        this.$store.commit("logOutUser");
                        this.$router.push('/login');
                    } else {
                        console.log('Err', err);
                    }
                });
            } else {
                this.isDataFetched = true;
            }
        },
        getSingleProductData(id){ 
            return _.find(this.productsInCart, { 'id': id });
        },
        closeSidebar(){
            this.$store.commit("changeLayout", 
                    { 
                        key: 'cartSidebar', 
                        value: false
                    })
        }
    },
    watch: {
        itemsIds() {
            this.getCartItemsData(this.itemsIds);
        }
    },
    mounted() {
        this.getCartItemsData(this.itemsIds);
    }
}
</script>
<style scoped></style>