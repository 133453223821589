<template>
        <div class="promoted-products-section" :class="{'gray': grayBg}">
            <div class="wrapper">
                <div class="header">
                    <h2>{{ header }}</h2>
                </div>
                <VSlickCarousel v-bind="settings" v-if="isDataFetched">
                    <ProductTile v-for="(item, key) in products" :key="key" :product="item"></ProductTile>                    
                </VSlickCarousel>
            </div>
        </div>
</template>
<script>
import axios from 'axios';
import 'v-slick-carousel/style.css'
import { VSlickCarousel } from 'v-slick-carousel'
import ProductTile from './ProductTile.vue';

export default{
name: 'PromotedProducts',
components: {
    VSlickCarousel,
    ProductTile
},
props: ['grayBg', 'data', 'header'],
data(){
    return {
        products: null,
        isDataFetched: false,
        settings: {
        groupsToShow: 1,
        slidesPerGroup: 2,
        arrows: false,
        dots: true,
        "responsive": [
    {
      "breakpoint": 480,
      "settings": {
        "slidesPerGroup": 2
      }
    },
    {
      "breakpoint": 680,
      "settings": {
        "slidesPerGroup": 3
      }
    },
    {
      "breakpoint": 1080,
      "settings": {
        "slidesPerGroup": 4
      }
    },
    {
      "breakpoint": 1400,
      "settings": {
        "slidesPerGroup": 5
      }
    }
  ]
        }
    }
},
methods: {
    getProductsData(){
        const apiURL = "wp/v2/products?include[]="+this.data.join('&include[]=');
        axios.get(apiURL).then( ({ data }) => {
            this.products = data;
            this.isDataFetched = true;
        } )
        .catch(err => {
            if(err.status === 403){
                this.$store.commit("logOutUser");
                this.$router.push('/login');
            } else {
                console.log('Err', err);
            }
        });
    },
},
watch:{
    $route (to, from){
        this.getProductsData()
    }
},
mounted(){
    this.getProductsData();
}
}
</script>
<style scoped>
</style>