<template>
<div class="wrapper">
            <div class="start-view-slider">
                <VSlickCarousel v-bind="settings">
                    <div class="item" v-for="(item, key) in data" :key="key">
                        <router-link :to="item.btn_link">
                            <div class="content">
                                <h2>{{ item.title }}</h2>
                                <p>{{ item.sub_title }}</p>
                                <button>{{ item.btn_content }}</button>
                            </div>
                            <div class="cover"></div>
                            <img :src="item.img" alt="">
                        </router-link>
                    </div>
                </VSlickCarousel>
            </div>
        </div>
</template>
<script>
import 'v-slick-carousel/style.css'
import { VSlickCarousel } from 'v-slick-carousel'

export default{
name: 'HomeStartViewSlider',
props: ['data'],
data(){
    return {
        settings: {
        groupsToShow: 1,
        dots: true,
        adaptiveHeight: true,
        lazyLoad: 'ondemand',
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: true,
        speed: 1000
        }
    }
},
components: {
    VSlickCarousel
},
}
</script>
<style scoped>
</style>