<template>
        <template v-if="isDataFetched">
        <div class="products-list" >
            <div class="items">
                <CartItem v-for="(item, key) in getCartItems" :key="key" :data="getSingleProductData(item.id)" :amount="item.amount"></CartItem>
            </div>
        </div>
        <div class="summary">
            <div class="inner">
                <CartSummaryTable :cartItems="getCartItems" :data="productsInCart"></CartSummaryTable>
                <router-link :to="'/checkout'" v-if="!ifCheckout"><button>Przejdź do formularza zamówienia</button></router-link>
            </div>
        </div>
        </template>
</template>
<script>
import axios from 'axios';

import CartSummaryTable from './CartSummaryTable';
import CartItem from '../parts/CartItem';

export default {
    name: 'CartSidebar',
    data() {
        return {
            isDataFetched: false,
            productsInCart: []
        }
    },
    components: {
        CartItem,
        CartSummaryTable
    },
    computed: {
        sidebarState() {
            return this.$store.state.layoutControllers.cartSidebar;
        },
        getCartItems() {
            return this.$store.getters.getCartItems;
        },
        countItemsInCart() {
            return this.$store.getters.countItemsInCart;
        },
        itemsIds() {
            let arr = [];
            this.getCartItems.forEach(elm => {
                arr.push(elm.id);
            });
            return arr.join([',']);
        },
        ifCheckout(){
        return this.$route.name === 'checkout' ? true : false;
        }
    },
    methods: {
        getCartItemsData(ids) {
            const apiURL = "wp/v2/products?include=" + ids;
            if (this.getCartItems[0]) {
                axios.get(apiURL).then(({ data }) => {
                    this.productsInCart = data;
                    this.isDataFetched = true;
                })
                .catch(err => {
                    if(err.status === 403){
                        this.$store.commit("logOutUser");
                        this.$router.push('/login');
                    } else {
                        console.log('Err', err);
                    }
                });
            }
        },
        getSingleProductData(id){ 
            return _.find(this.productsInCart, { 'id': id });
        },
        closeSidebar(){
            this.$store.commit("changeLayout", 
                    { 
                        key: 'cartSidebar', 
                        value: false
                    })
        }
    },
    watch: {
        itemsIds() {
            this.getCartItemsData(this.itemsIds);
        }
    },
    mounted() {
        this.getCartItemsData(this.itemsIds);
    }
}
</script>
<style scoped></style>