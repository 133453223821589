<template>
     <div class="categories-nav">
                <ul>
                    <li v-for="(item, key) in getMainCategories">
                        <router-link :to="'/' + item.slug">
                            <button>
                                <div class="icon">
                                    <img src="@/assets/images/cat-icon.svg" alt="">
                                </div>
                                <span>{{ item.name }}</span>
                            </button>
                        </router-link>
                    </li>
                </ul>
            </div>
</template>
<script>
export default{
name: 'HomeCategoriesList',
computed: {
    getCategories(){
        return this.$store.getters.getCategories;
    },
    getMainCategories(){
        return _.filter(this.getCategories, { 'parent': 0 });
    }
},
mounted(){
    if(!this.$store.getters.getCategories){
        this.$store.commit("setCategories");
    }
}

}
</script>
<style scoped>
</style>