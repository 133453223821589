<template>
    <div>
    
        <table>
            <tbody>
                <tr v-if="cartSum.userPriceEur < cartSum.regularEur">
                    <td>Suma (cena katalogowa):</td>
                    <td><del>{{ cartSum.regularEur }}</del> €</td>
                </tr>
                <tr>
                    <td v-if="cartSum.userPriceEur < cartSum.regularEur">Suma (po rabacie):</td>
                    <td v-else>Suma:</td>
                    <td>{{ cartSum.userPriceEur }} €</td>
                </tr>
                <tr>
                    <td><b>Razem w PLN:</b>Kurs EUR/PLN: {{ getRate.value }} zł</td>
                    <td>{{ cartSum.pln }} zł</td>
                </tr>
            </tbody>
        </table>
        <p class="price-info">Podane ceny są kwotami netto, do których zostanie doliczone 23% podatku VAT.</p>
    </div>
</template>
<script>
import _ from 'lodash';
export default {
    name: 'CartSummaryTable',
    props: ['data', 'cartItems'],
    computed: {
        getRate(){
            return this.$store.getters.getRate;
        },
        cartSum(){
            let prices = {
                regularEur: 0,
                userPriceEur: 0,
                pln: 0
            }
            this.cartItems.forEach(elm => {
               let item = _.find(this.data, { 'id': elm.id });
                prices.regularEur += elm.amount*Number(item.acf.regular_price).toFixed(2);
                prices.userPriceEur += elm.amount*Number(item.prices.EUR).toFixed(2);
                prices.pln += elm.amount*Number(item.prices.PLN).toFixed(2);
            });
            prices.regularEur = prices.regularEur.toFixed(2);
            prices.userPriceEur = prices.userPriceEur.toFixed(2);
            prices.pln = prices.pln.toFixed(2);

            return prices;
        }
    },
    mounted(){

        if(this.$store.getters.getRate){
            const today = new Date();
            const todayFormatted = today.toISOString().split('T')[0];
            if(this.$store.getters.getRate.date != todayFormatted){
              this.$store.commit("updateExchangeRate");
            }
        }

    }
}
</script>
<style scoped></style>