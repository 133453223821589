<template>
    <section id="home-page" class="page-container" :class="{'preload': !isDataFetched}">
        <div v-if="isDataFetched">
            <HomeStartViewSlider :data="data.start_slider"></HomeStartViewSlider>
            <HomeCategoriesList></HomeCategoriesList>
            <PromotedProductsSlider :data="data.promoted_products" :header="data.promoted_header"></PromotedProductsSlider>
            <PromotedProductsSlider v-if="recentlyViewedProducts[0]" :data="recentlyViewedProducts" :grayBg="true" :header="'Ostatnio oglądane'"></PromotedProductsSlider>
        </div>

    </section>
</template>
<script>
import axios from 'axios';

import PromotedProductsSlider from './parts/PromotedProductsSlider.vue';
import HomeStartViewSlider from './parts/HomeStartViewSlider';
import HomeCategoriesList from './parts/HomeCategoriesList';

export default{
name: 'Home',
data(){
    return {
        data: null,
        isDataFetched: false
    }
},
components: {
    PromotedProductsSlider,
    HomeStartViewSlider,
    HomeCategoriesList
},
computed: {
    recentlyViewedProducts(){
        return this.$store.getters.getRecentlyViewed;
    }
}, 
methods:{
    getHomepageData(){
        const apiURL = "wp/v2/pages?slug=homepage&_fields=acf&acf_format=standard";
        axios.get(apiURL).then( ({ data }) => {
            this.data = data[0].acf;
            this.isDataFetched = true;
        })
        .catch(err => {
            if(err.status === 403){
                this.$store.commit("logOutUser");
                this.$router.push('/login');
            } else {
                console.log('Err', err);
            }
        });
    },
},
mounted(){
    this.getHomepageData();
},
created(){
    if(localStorage.getItem("user")){
        const userToken = JSON.parse(localStorage.getItem("user")).token;
        axios.defaults.headers.common['Authorization'] = 'Bearer '+userToken;

        this.$store.commit("updateExchangeRate");
    }
}
}
</script>
<style scoped>
</style>