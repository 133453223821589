<template>
    <div class="wrapper">
        <div class="left-side">
        <CheckoutForm @formResponse="formResponse"></CheckoutForm>
        </div>
        <div class="right-sidebar">
        <div class="header">
            <h2>Podsumowanie</h2>
        </div>
        <button class="showCart">Podejrzyj koszyk</button>
        <CartProductsList></CartProductsList>
        </div>
    </div>
</template>
<script>
import $ from "jquery";

import CheckoutForm from './CheckoutForm';
import CartProductsList from './CartProductsList';
export default{
name: 'CheckoutSummary',
components: {
        CartProductsList,
        CheckoutForm
    },
    methods: {
        formResponse(value){
            this.$emit("formResponse", value);
        }  
    },
    mounted(){
    $('button.showCart').on('click', function(e){
        $('.products-list .items').slideToggle(300);
    });
}
}
</script>
<style scoped>
</style>