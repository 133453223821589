import axios from 'axios';
import _, { each } from 'lodash';
export default {
    setUserData(state, payload){
        state.user = payload;
    },
    setCartData(state, payload){
        return state.cart = payload;
    },
    logOutUser(state){
        state.user.token = '';
        state.user.name = '';
        localStorage.removeItem("user");
    },
    setCategories(state){
        axios.get("wp/v2/products-categories?per_page=100")
        .then( ({ data }) => state.categories = data );
    },
    setFavourites(state, payload){
        return state.favourites = payload;
    },
    updateExchangeRate(state){
        axios.get("fluidra/v1/get-exchange-rate")
        .then( ({ data }) => state.exchangeRate = data );
    },
    updateAmountInCart(state, payload){
        let currentIndex = _.findIndex(state.cart, function(o) { return o.id == payload.id; });
         if(currentIndex >= 0){
            state.cart[currentIndex].amount = payload.amount;
         }
         localStorage.setItem("cart", JSON.stringify(state.cart));
     },
     addItemToCart(state, payload){
        let currentIndex = _.findIndex(state.cart, function(o) { return o.id == payload.id; });
         if(currentIndex >= 0){
            state.cart[currentIndex].amount += payload.amount;
         } else {
             state.cart.push(payload);
         }
         localStorage.setItem("cart", JSON.stringify(state.cart));
     },
    addToFavourites(state, id){
        state.favourites.push(id);
    },
    removeFromFavourites(state, id){
       let index = _.findIndex(state.favourites, id);
       return state.favourites.splice(index, 1);
    },
    changeLayout(state, payload){
        state.layoutControllers[payload.key] = payload.value;
        if(payload.key === "navigationSidebar" || payload.key === "cartSidebar"){
            state.layoutControllers.mask = payload.value;
        }
    },
    hideAllSidebars(state){
        for (const property in state.layoutControllers) {
            state.layoutControllers[property] = false;
        }
    },
    removeFromCart(state, payload){
        let index = _.findIndex(state.cart, { 'id': payload });
        state.cart.splice(index, 1);
        localStorage.setItem("cart", JSON.stringify(state.cart));
    },
    resetCart(state){
        state.cart = [];
        localStorage.removeItem("cart");
    },
    addNewToRecentlyViewed(state, payload){
        if(state.recentlyViewed.length === 10){
            state.recentlyViewed.splice(0, 1);
        }
        state.recentlyViewed.push(payload);
        state.recentlyViewed = [...new Set(state.recentlyViewed)];
        localStorage.setItem("recentlyViewed", JSON.stringify(state.recentlyViewed));

    },
    setRecentlyViewed(state, payload){
        state.recentlyViewed = payload;
    }
}