<template>
<section id="my-orders" class="page-container" :class="{'preload': !isDataFetched}">
    <div class="wrapper">
        <div class="header">
            <h1>Moje zamówienia</h1>
        </div>
    </div>
   <div class="wrapper" v-if="isDataFetched && orders[0]">
    <table>
        <thead>
            <tr>
                <th>Zamówienie</th>
                <th>Data złożenia zamówienia</th>
                <th>Osoba kontaktowa</th>
                <th>Akcja</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(item, key) in orders" :key="key">
                <td>#{{ item.id }}</td>
                <td>{{ item.order_date }}</td>
                <td>{{ item.client_name }}</td>
                <td><a :href="getApiUrl+'fluidra/v1/pdf-offer/'+item.download_id" target="_blank"><button>Zobacz szczegóły</button></a></td>
            </tr>
        </tbody>
    </table>
   </div>

   <section id="no-results" v-else>
        <div class="wrapper">
        <div class="icon">
            <img src="@/assets/images/empty-folder.svg" class="" loading="lazy" />
        </div>
        <div class="desc">
            <p>Nie złożyłeś jeszcze żadnego zamówienia.</p>
            <ul>
                <li><router-link :to="'/'"><button>Strona główna</button></router-link></li>
            </ul>
        </div>
        </div>
    </section>
</section>
</template>
<script>
import axios from 'axios';
export default{
name: 'AccountPage',
data(){
    return {
        orders: null,
        isDataFetched: false
    }
},
methods: {
    async getOrders(){
        const path = "fluidra/v1/offers";
        try{
            let response = await axios.get(path);
            this.orders = response.data;
            this.isDataFetched = true;
        } catch(err){
          console.log('Err', err)
        }
      },
},
computed: {
    getApiUrl(){
        return axios.defaults.baseURL;
    }
},
watch:{
    $route (to, from){
        this.getOrders();
    }
},
mounted(){
    this.getOrders();
}
}
</script>
<style scoped>
</style>