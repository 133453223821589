<template>
    <div class="item product-tile">
        <button v-if="ifFavourites" @click.prevent="removeFromFavourites(product.id)" class="remove"><img src="@/assets/images/close.svg" class="" loading="lazy"></button>
        <router-link :to="'/product/'+product.slug">
        <div class="inner">
            <div class="thumb">
                <img :src="product.featured_image_src" alt="">
            </div>
            <span class="name" v-html="product.title.rendered"></span>
            <span class="code">{{ product.acf.code }}</span>
        </div>
    </router-link>
    </div>
</template>
<script>
export default {
    name: 'ProductTile',
    props: ['product'],
    computed: {
        ifFavourites(){
        return this.$route.name === 'favourites' ? true : false;
        }
    },
    methods: {
        removeFromFavourites(id){
            this.$emit("removeFromFavourites", id);
        }
    }
}
</script>
<style scoped></style>