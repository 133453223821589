import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from "./router/router";
import store from "./store/store";
import VueAxios from "vue-axios";
import axios from "axios";

// DEV
//axios.defaults.baseURL = 'http://astralpool-api.local/wp-json/';

// PROD
axios.defaults.baseURL = 'https://fluidra-api.thelion.online/api/wp-json/';


if(localStorage.getItem("user")){
    const userToken = JSON.parse(localStorage.getItem("user")).token;
    axios.defaults.headers.common['Authorization'] = 'Bearer '+userToken;
}


createApp(App)
.use(router)
.use(store)
.use(VueAxios, axios)
.mount('#app')

