<template>
<section id="category-page" class="page-container" :class="{'preload': !isDataFetched}">
    <section id="category-page-header" v-if="isDataFetched">
        <div class="wrapper">
            <div class="header favourites">
             <h1 class="categoryName">Ulubione <span class="amount">{{ products.length }}</span></h1>
            </div>
        </div>
    </section>
    <section id="products-list" v-if="products.length > 0 && isDataFetched">
        <div class="wrapper">
            <div class="items">
                <ProductTile 
                v-for="(item, key) in products" 
                :key="key" 
                :product="getProductObj(item.id)"
                @removeFromFavourites="removeFromFavourites"></ProductTile>
            </div>
        </div>
    </section>
    <section id="no-results" v-if="products.length === 0 && isDataFetched">
        <div class="wrapper">
        <div class="icon">
            <img src="@/assets/images/empty-folder.svg" class="" loading="lazy" />
        </div>
        <div class="desc">
            <p>Nie dodałeś jeszcze żadnych produktów do ulubionych.</p>
            <ul>
                <li><router-link :to="'/'"><button>Strona główna</button></router-link></li>
            </ul>
        </div>
        </div>
    </section>
</section>
</template>
<script>
import axios from 'axios';
import _ from 'lodash';
import ProductTile from './parts/ProductTile'
export default{
name: 'FavouritesPage',
data(){
    return {
        favourites: [],
        products: [],
        isDataFetched: false
    }
},
components: {
    ProductTile
},
methods: {
    async getProducts(){
        const setFavArr = "fluidra/v1/fav-product";
        try{
            let response = await axios.get(setFavArr);
            this.favourites = response.data;
           
              if(this.favourites.length > 0){
                let apiProductsURL = "wp/v2/products?include[]="+this.favourites.join('&include[]=');
                
                let response2 = await axios.get(apiProductsURL);
                console.log(response2.data);
                this.products = response2.data;
              }

            
            this.isDataFetched = true;

        } catch(err){
          console.log('Err', err)
        }
      },
      getProductObj(id){
        let obj = _.find(this.products, { 'id': Number(id) });
        return obj;
    },
    removeFromFavourites(id){
        axios.delete("fluidra/v1/fav-product/"+id).then(({data}) => { 
            let index = _.findIndex(this.products, function(o) { return o.id == id; });
            this.products.splice(index, 1);
        })
        .catch(err => console.log('Err', err));
    }
},
watch:{
    $route (to, from){
        this.getProducts();
    }
},
mounted(){
    this.getProducts();

}
}
</script>
<style scoped>
</style>