<template>
    <div class="wrapper infoPanel">
        <div class="icon">
                <img src="@/assets/images/checkout-done.svg" class="" loading="lazy" />
            </div>
            <div class="desc">
                <h2>Twoje zamówienie zostało pomyślnie przesłane</h2>
                <p>Na Twój adres email otrzymasz podsumowanie w formie pdf.</p>
                <div class="btn">
                    <router-link :to="'/'">
                        <button class="back"><span>Wróć na stronę główną</span></button>
                    </router-link>
                </div>
            </div>
        </div>
</template>
<script>
export default{
name: 'CheckoutSuccess'
}
</script>
<style scoped>
</style>