<template>
    <div class="item">
        <div class="thumb">
            <button class="remove" @click.prevent="removeFromCart(data.id)">
                <img src="@/assets/images/close.svg" class="" loading="lazy" />
            </button>
            <img :src="data.featured_image_src" class="" loading="lazy" />
        </div>
        <div class="desc">
            <router-link :to="'/product/'+data.slug"><span class="productName" v-html="data.title.rendered"></span></router-link>
            <p class="atributes">{{data.acf.code}}</p>
            <div class="amount-select">
                <span>Ilość: </span>
                <div class="amount">
                    <button class="less" @click.prevent="removeOne()">-</button>
                    <input type="number" value="1" min="1" v-model="items" @change.prevent="updateCart()">
                    <button class="more" @click.prevent="addOne();">+</button>
                </div>
            </div>
            <div class="price">
                <span class="current">{{ currentPrice }} €</span>
                <span class="del" v-if="currentPrice < regularPrice">{{ regularPrice }} €</span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'CartItem',
    props: ['data', 'amount'],
    data(){
        return {
            items: null
        }
    },
    methods: {
        removeFromCart(id){
            this.$store.commit("removeFromCart", id);
        },
        updateCart(){
            this.$store.commit("updateAmountInCart", {
                id: this.data.id,
                amount: this.items
            });
        },
        removeOne(){
            if(this.items > 1){
                this.items--;
                this.updateCart(); 
            } else {
                this.removeFromCart(this.data.id);
            }
        },
        addOne(){
            this.items++;
          this.updateCart();  
        }
    },
    computed: {
        currentPrice(){
            return Number(this.data.prices.EUR).toFixed(2);
        },
        regularPrice(){
            return this.data.acf.regular_price.toFixed(2);
        },
    },
    mounted(){
        this.items = this.amount;
    }
}
</script>
<style scoped></style>