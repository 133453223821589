<template>
<div>
    <section id="single-product" class="page-container" :class="{'preload': !isDataFetched}">
        <BreadcrumbsNav v-if="isDataFetched && getPathToBreadcrumbs[0]" :data="getPathToBreadcrumbs"></BreadcrumbsNav>
    <div class="wrapper"  v-if="isDataFetched">
        <div class="product">
            <div class="oneoftwo">
                <div class="product-img">
                    <img :src="product.featured_image_src" :alt="product.title.rendered">
                </div>
            </div>
            <div class="oneoftwo">
                <h1 class="productName" v-html="product.title.rendered"></h1>
                <span class="code">{{ product.acf.code }}</span>
                <div class="global-content-config" v-if="product.content.rendered" v-html="product.content.rendered"></div>
                
                
               <template v-if="product.acf.typ_produktu === 'zapytaj'">
                <p class="price-info">Zapytaj o cenę. Prześlij zapytanie na adres email <a href="mailto:biuro@fludra.com">biuro@fludra.com</a>.</p>
                </template>
                <template v-if="product.acf.typ_produktu === 'koniec'">
                    <p class="price-info">Koniec produkcji.</p>
                </template>
                <template v-if="product.acf.typ_produktu === 'cena'">
                    <ul class="price">
                        <li class="discount" v-if="currentPrice < regularPrice">-{{ 100-((currentPrice / regularPrice)*100).toFixed(0) }}%</li>
                        <li class="currentPrice">{{ currentPrice }} €</li>
                        <li class="regularPrice" v-if="currentPrice < regularPrice"><span>{{ regularPrice }}</span> €</li>
                    </ul>
                    <p class="price-info">Podane ceny są kwotami netto, do których zostanie doliczone 23% podatku VAT.</p>
                </template>


                <div class="cart-favourites">
                    <form action="#" v-if="product.acf.typ_produktu === 'cena'">
                        <div class="amount">
                            <button class="less" @click.prevent="amount > 1 ? amount-- : amount;">-</button>
                            <input type="number" value="1" min="1" v-model="amount">
                            <button class="more" @click.prevent="amount++;">+</button>
                        </div>
                        <button class="submit addToCart" @click.prevent="addToCart(product.id, amount)"><span>Dodaj do koszyka</span></button>
                    </form>
                    <button class="addToFavourites" @click.prevent="addToFavourites(product.id)"><span>Dodaj do ulubionych</span></button>
                </div>
            </div>
        </div>
    </div>
    <div class="promoted-products-section" v-if="relatedWithoutCurrent[0] && isDataFetched">
            <div class="wrapper">
                <div class="header">
                    <h2>Powiązane produkty</h2>
                </div>
                <VSlickCarousel v-bind="settings">
                    <ProductTile v-for="(item, key) in relatedWithoutCurrent" :key="key" :product="item"></ProductTile>                    
                </VSlickCarousel>
            </div>
    </div>
</section>

</div>
</template>
<script>
import axios from 'axios';
import _ from 'lodash';
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css'; 

import { VSlickCarousel } from 'v-slick-carousel'
import ProductTile from './parts/ProductTile.vue';
import BreadcrumbsNav from './parts/BreadcrumbsNav';

export default{
name: 'SingleProduct',
data(){
    return {
        product: null,
        relatedProducts: [],
        amount: 1,
        isDataFetched: false,
        settings: {
        groupsToShow: 1,
        slidesPerGroup: 2,
        arrows: false,
        dots: true,
        "responsive": [
    {
      "breakpoint": 480,
      "settings": {
        "slidesPerGroup": 2
      }
    },
    {
      "breakpoint": 680,
      "settings": {
        "slidesPerGroup": 3
      }
    },
    {
      "breakpoint": 1080,
      "settings": {
        "slidesPerGroup": 4
      }
    },
    {
      "breakpoint": 1400,
      "settings": {
        "slidesPerGroup": 5
      }
    }
  ]
        }
    }
},
components: {
    BreadcrumbsNav,
    VSlickCarousel,
    ProductTile
},
computed: {
    isInFavourite(){
        let favourites = this.$store.getters.getFavourites;
    },  
    currentPrice(){
        return Number(this.product.prices.EUR).toFixed(2);
    },
    regularPrice(){
        return this.product.acf.regular_price.toFixed(2);
    },
    getProductSlug(){
        return this.$route.params.productSlug;
    },
    getPathToBreadcrumbs(){
        let path = [];
        if(this.$store.getters.getCategories){
            this.product['products-categories'].forEach(elm => {
                let category = _.filter(this.$store.getters.getCategories, { 'id': elm });
                    if(category[0]){
                        path.push({
                            slug: category[0].slug,
                            name: category[0].name,
                            parent: category[0].parent
                        })
                    }
            });
            path = _.sortBy(path, [['parent']]);
        }
        path.push({
            slug: this.product.slug,
            name: this.product.title.rendered
        })
        return path;
    },
    relatedWithoutCurrent(){
        let items = []
        this.relatedProducts.forEach(elm => {
            if(elm.id !== this.product.id){
                items.push(elm);
            }
        });
        return items;
    }
},
methods: {
    async getSingleProduct(){
        const apiProductURL = "wp/v2/products?slug="+this.getProductSlug;
        try{
            let response = await axios.get(apiProductURL);
            this.product = response.data[0];
            this.addToRecentlyViewed(this.product.id);
            let productCategoryId = this.product['products-categories'][1] ? this.product['products-categories'][1] : this.product['products-categories'][0];
            if(productCategoryId){
                let apiRelatedURL = "wp/v2/products?products-categories="+productCategoryId; 
                let response2 = await axios.get(apiRelatedURL);
                this.relatedProducts = response2.data;
            }
            this.isDataFetched = true;
           
        } catch(err){
            if(err.status === 403){
                this.$store.commit("logOutUser");
                this.$router.push('/login');
            } else {
                console.log('Err', err);
            }
        }
      },
      addToRecentlyViewed(id){
        this.$store.commit('addNewToRecentlyViewed', id);
    },
    addToFavourites(id){
        axios.post("fluidra/v1/fav-product/"+id).then(({data}) => { 
        })
        .catch(err => console.log('Err', err));

        const notyf = new Notyf({
            dismissible: true,
        });
        const notification = notyf.success('Dodano do ulubionych.');
        notification.on('click', ({target, event}) => {
            this.$router.push('/favourites');
        });
    },
    addToCart(id, amount){
        this.$store.commit("addItemToCart", {
            id,
            amount
        });

        const notyf = new Notyf({
            dismissible: true,
        });

        const notification = notyf.success('Dodano do koszyka.');
        notification.on('click', ({target, event}) => {
            this.$store.commit("changeLayout", 
                { 
                    key: 'cartSidebar', 
                    value: true
                })
        });
    }
},
watch:{
    $route (to, from){
        this.getSingleProduct()
    }
},
mounted(){
  
    this.getSingleProduct()
}
}
</script>
<style scoped>
</style>