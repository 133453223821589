<template>
<section id="checkout" class="page-container">
    <component :is="component" @formResponse="formResponse"></component>
</section>
</template>
<script>
import CheckoutSummary from './parts/CheckoutSummary';
import CheckoutSuccess from './parts/CheckoutSuccess';
import CheckoutFailure from './parts/CheckoutFailure';

export default{
name: 'CheckoutPage',
    components: {
        CheckoutSummary,
        CheckoutSuccess,
        CheckoutFailure
        
    },
    data(){
        return {
            component: 'CheckoutSummary'
        }
    },
    methods: {
        formResponse(value){
            this.component = value;
        }
    }
}
</script>
<style scoped>
</style>